.app {
  height: 100vh;
  display: flex;
  gap: 10px;
}

.grid-container {
  flex: 1 0 auto;
}

.my_center {
  text-align: center;
}

.draggable {
  width: 100px;
  height: 100px;
  background-color: green;
}

.cellWithStatus {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  text-align: justify;
  text-align-last: justify;
  text-justify: distribute-all-lines; /* cp的~ 兼容ie浏览器 */
  &.Unknown {
    color: #23b1ee;
    background-color: rgba(136, 224, 208, 0.5);
  }

  &.Processing {
    color: #20d7b8;
    background-color: rgba(138, 239, 222, 0.5);
  }

  &.Finish {
    color: #59ad2f;
    background-color: rgba(205, 255, 189, 0.5);
  }

  &.Ordered {
    color: #0042ff;
    background-color: rgba(180, 202, 255, 0.5);
  }

  &.Error {
    color: #ff0033;
    background-color: rgba(255, 179, 179, 0.5);
  }

  &.UnknownError {
    color: #ff0033;
    background-color: rgba(255, 179, 179, 0.5);
  }

  &.Void {
    color: #7600ff;
    background-color: rgba(231, 218, 255, 0.5);
  }
}

.AdminCellWithStatus {
  padding: 5px;
  border-radius: 5px;
  text-align: justify;
  width: 100%;
  text-align-last: justify;
  text-justify: distribute-all-lines; /* cp的~ 兼容ie浏览器 */
  &.initializing {
    color: #1ea6aa;
    background-color: rgb(151, 252, 255);
  }
  &.Processing {
    color: #20d7b8;
    background-color: rgba(138, 239, 222, 0.5);
  }

  &.Complete {
    color: #59ad2f;
    background-color: rgba(205, 255, 189, 0.5);
  }
  &.Error {
    color: #ff0033;
    background-color: rgba(255, 179, 179, 0.5);
  }
  &.Reset {
    color: #0021fd;
    background-color: rgba(116, 160, 255, 0.5);
  }
  &.Resetting {
    color: #5400fd;
    background-color: rgba(148, 177, 253, 0.5);
  }
}

.ClaimCellWithStatus {
  padding: 5px;
  border-radius: 5px;
  text-align: justify;
  width: 100%;
  text-align-last: justify;
  text-justify: distribute-all-lines; /* cp的~ 兼容ie浏览器 */
  &.n {
    color: #ff0033;
    background-color: rgba(246, 142, 142, 0.5);
  }
  &.y {
    color: #59ad2f;
    background-color: rgba(205, 255, 189, 0.5);
  }
  &.f {
    color: #1ea6aa;
    background-color: rgb(151, 252, 255);
  }
  &.t {
    width: 100%;
    color: #0021fd;
    background-color: rgba(116, 160, 255, 0.5);
  }
}

.RaterCellWithStatus {
  padding: 5px;
  border-radius: 5px;
  text-align: justify;
  width: 100%;
  text-align-last: justify;
  text-justify: distribute-all-lines; /* cp的~ 兼容ie浏览器 */
  &.init {
    color: #1ea6aa;
    background-color: rgb(151, 252, 255);
  }
  &.enable {
    color: #59ad2f;
    background-color: rgba(205, 255, 189, 0.5);
  }
  &.objection {
    color: #ff0033;
    background-color: rgba(255, 179, 179, 0.5);
  }
  &.deactivate {
    color: #ff0033;
    background-color: rgba(255, 179, 179, 0.5);
  }
}

